<template>
    <div class="page-poi-task-list">
      <Search
        @on-edit="onEdit"
        @on-search="handle_search"
      />
      <Table
        class="task-table"
        :columns="columns"
        :times="times"
        :dataSource="dataSource"
      >
        <template #action="{ row }">
          <div class="action">
            <span v-if="row.isShow === false" title="查看" class="iconfont icon-yanjing_yincang_o" @click="chang(row, true)" style="margin-right: 4px" ></span>
            <span v-else title="隐藏" class="iconfont icon-yulanhangxian" @click="chang(row, false)"></span>
            <span title="更改" class="iconfont icon-genggai" @click="edit(row)"></span>
            <span title="删除" class="iconfont icon-shanchu" @click="del(row)"></span>
          </div>
        </template>
      </Table>    
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="20%"
        :modal="false"
        >
        <span style="color: #fff;font-size: 16px;">是否确认删除？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmDel(info)">确 定</el-button>
        </span>
</el-dialog>
    </div>
</template>

<script>
import Box from "@/components/platform/common/box";
import Search from "./search.vue";
import Table from "@/components/common/table";
import { props } from "./props";
import API from "@/api";
export default {
  props: _.pick(props, ["onEdit", "edit", "show"]),
  components: { Box, Search, Table },
  methods: {},
  data() {
    return {
      columns: [
        { prop: "name", label: "三维名称" },
        { prop: "url", label: "数据链接" },
        { prop: "action", slot: true, label: "操作" },
      ],
      times: null,
      dataSource: [],
      search: {},
      baseUrl: null,
      dialogVisible: false,
      info: {},
      isShow: false,
    };
  },
  methods: {
    async async_get_list() {
      let { data } = await API.MODEL.List({
        ...this.search,
        pageNo: 1,
        pageSize: 100000,
      });
      this.dataSource = data.data || [];
      // 每条数据添加一个isShow来控制图标显隐藏
      this.dataSource.map(item => item.isShow = false)
    },
    handle_search(data) {
      this.search = data;
      this.async_get_list();
    },
    del(value) {
      this.dialogVisible = true;
      this.info = value;
    },
    async confirmDel(value) {
      let res;
      if (value) {
            res = await API.MODEL.delInfo({id: value.id})
          }
          if ( res.status === 1) {
            this.dialogVisible = false
            this.$el_message('删除成功')
            this.async_get_list()
          } else {
            this.dialogVisible = false
            this.$message.error(res.message);
          }
    },
    chang(data, type) {
      console.log(data,'this.dataSource');
      // 获取点击的数据下标，实时改变table数据
      let index = this.dataSource.findIndex(item => item.id === data.id)
      this.$set(this.dataSource, index , {...data, isShow: type });
      this.show(data,type)
    }
  },
  mounted() {
    this.async_get_list();
    this.baseUrl = process.env.VUE_APP_WS_IP;
  },
};
</script>

<style lang="scss" scoped>
.page-poi-task-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .task-table {
    ::-webkit-scrollbar {
      width: 2px; /*滚动条宽度*/
      height: 16px; /*滚动条高度*/
    }
    flex: 1;
    margin-top: 10px;
    .action {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      .iconfont {
        cursor: pointer;
        font-size: 18px;
        color: #9fc4ff;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
