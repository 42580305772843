<template>
  <div class="model" >
    <Draw :model="list" ref="child" />
    <div class="floating-menu">
        <FiexdRight/>
    </div>
    
    <!-- 右下视图切换 -->
    <Bottomright  />
  </div>
</template>

<script>
import API from "@/api";
import FiexdRight from "@/components/fiexdright";
import Draw from "@/components/draw";
import TaskList from "./list";
import Bottomright from '@/components/Bottomright';
export default {
  components: {
    Draw,
    FiexdRight,
    TaskList,
    Bottomright
  },
  data() {
    return {
      threeDIsShow: true, // 三维列表的显示隐藏
      list : {
        addtime: 1652234736000,
        catalogId: 2,
        catalogName: null,
        children: [],
        content: null,
        guid: null,
        id: 590,
        mapList: [],
        name: "商洛滑坡",
        pid: 0,
        remark: null,
        status: 1,
        type: null,
        url: "",
      },
      qf:false,
       editProps: null,
      visible: {
        edit: false,
        add: true,
        notice: false,
      },
      edit_form: {
        catalogId: 2,
        url: null,
        name: null,
      },
      rules: {
        edit_form: {
          name: [
            { required: true, message: "请输入模型名称", trigger: "blur" },
          ],
          url: [
            { required: true, message: "请输入数据链接", trigger: "blur" },
            {
            pattern:/^http.*json$/gi,
            message: "请输入正确数据链接",
          },
          ],
        },
      },
      typeList: [
        {id: 1, typeName: "二维正射"},
        {id: 2, typeName: "三维正射"},
        {id: 3, typeName: "其它"},
      ],
      baseUrl: null
    }
  },
  methods: {
    tjurl(data, isShow){
      this.list = data;
      this.$refs.child.sing(data, isShow);
    },
    async handleSave() {
      this.$refs["edit_form"].validate(async (valid) => {
        if (valid) {
          let res;
          if (this.editProps) {
            res = await API.MODEL.upInfo({...this.edit_form, id: this.editProps.id})
          } else {
            res = await API.MODEL.addInfo(this.edit_form)
          }
          
          if ( res.status === 1) {
            this.$el_message(this.editProps ? '修改成功' : '新增成功')
            this.$refs["edit_form"].resetFields();
            this.editProps = null
            this.visible.edit = false
            this.$refs.listRef.async_get_list()
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    addList() {
      this.visible.edit = true;
      this.edit_form={
        catalogId: 2,
        name: null,
        url: null
         } 
      },
    edit(value) {
      this.editProps = value;
      this.init_form(value)
    },
    init_form(data) {
      Object.keys(this.edit_form).forEach((key) => {
        this.edit_form[key] = data[key] || null;
      });
    },
    close() {
      this.visible.edit = false
      //  this.editProps = null
      // this.$refs["edit_form"].resetFields();
    },
    closed() {
       this.editProps = null
      this.$refs["edit_form"].resetFields();
    },
    
    
    
  },
   mounted() {
    this.baseUrl = process.env.VUE_APP_WS_IP;
  },
  
  inject: ["toggle_mode"],
}
</script>

<style lang="scss" scoped>
  ::v-deep{
    .el-collapse-item__header{
      font-size: 16px;
      font-weight: 600;
      color: #9FC4FF;
    }
    .el-collapse-item__content{
      padding-bottom: 16px;
    }
    .el-collapse-item__header{
      background: rgba(82, 39, 39, 0);
    }
    .el-collapse-item__wrap{
      background: rgba(82, 39, 39, 0);
      border: 0px;
    }
    .el-collapse-item__header{
      border: 0px;
    }
    .el-collapse{
      border: 0px;
    }
    .title{
      .el-icon-close{
        font-size: 22px;
      }
    }
  }
  .model .cpt-draw{
    height: 100%;
    bottom: 100%;
    top: 0;
    /* position: relative; */
  }
    
  .console{
    position: absolute;
    z-index: 1;
    top: 80px;
    left: 40px;
    // width: 316px;
    // width: 416px;
    height: 740px;
    // padding: 14px 0px;
    padding-top: 20px;
    border: solid 1px #0097FB;
    background: rgba(8, 37, 79, 0.8);
    .title{
      position: absolute;
      left: 0;
      top: -20px;
      width: 100%;
      text-align: center;
      width: 60%;
      height: 34px;
      color: #fff;
      display: inline-block;
      margin: 0 auto;
      background-image: radial-gradient(circle at 50% 127%, rgba(0, 161, 255, 0.3) 0%, rgba(17, 31, 57, 0) 100%);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #cce8ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      .close{
        position: absolute;
        top: 4px;
        right: -74px;
        font-size: 28px;
      }
    }
    .list{
      max-height: 300px;
      overflow-y: auto;
      padding: 1px 0px;
      .item{
        color: #9FC4FF;
        margin-bottom: 6px;
        border: 1px solid rgba(0, 154, 255, 0.8);
        position: relative;
        box-sizing: border-box;
        width: 98%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        .img{
          position: absolute;
          top: 0px;
          right: 20px;
        }
      }
    }
  }
  .floating-menu {
  position: fixed;
  top: 50%;
  margin-top: -30px !important;
  right: 5px;
  width: 50px;
  height: 260px;
  text-align: center;
  z-index: 1;
}
.page-task-list {
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  .task-list-notice-wrap {
    display: flex;
    .map-box {
      width: 436px;
      height: 274px;
      border: 1px solid red;
      flex-shrink: 0;
      box-sizing: border-box;
      margin-right: 16px;
    }
    .props-box {
      flex: 1;
      box-sizing: border-box;
      .prop-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
        .prop-field-k {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          text-align: left;
          font-weight: 400;
          width: 98px;
        }
        .prop-field-v {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
  }
}
</style>